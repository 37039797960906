@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.topic-content {
  position: relative;
  .detail-content {
    border-left: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    border-radius: 4px;
    background: #fff;
    position: absolute;
    top: 32px;
    font-size: 14px;
    width: 480px;
    padding: 0 10px;
  }
}
