@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.el-divider {
  background: rgba(236, 234, 250, 1);
}
.tab-title {
  width: 80px;
  height: 24px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
}
.row {
  display: flex;
}
.rowac {
  display: flex;
  align-items: center;
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197, 192, 224, 1);
  height: 250px;
  padding: 0 10px;
  white-space: pre-wrap;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.remark-text {
  color: #999999;
  margin-left: 20px;
}
.theatre-tab {
  width: 212px;
  height: 24px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.theatre-content {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
  .del {
    width: 76px;
    height: 24px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #f394ac;
    line-height: 21px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #f394ac;
  }
}
.computed-title {
  padding: 3px 8px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 40px;
  width: 70px;
  text-align: center;
  border-left: 4px solid #c5c0e0;
  height: 20px;
}
